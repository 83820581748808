import BaseComponent from './base-component'
const scrollToElement = require('scroll-to-element')

const CLASS = 'abr-mHeader'

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`,
  SCROLL_TRIGGER: `.${ClassName.ROOT}-action--menu`
}

export default class Header extends BaseComponent {
  static upgradeAll() {
    let components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach((element) => {
      if (element.instance == null) element.instance = new Header(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    this._bindEventHandlers()
  }

  _bindEventHandlers() {
    const trigger = this.element.querySelector(Selector.SCROLL_TRIGGER)
    if (trigger) {
      trigger.addEventListener(
        'click',
        (this._handleExpanderClick = () => {
          scrollToElement('.abr-mMenu', {
            offset: -50,
            ease: 'inOutExpo',
            duration: 1500
          })
        })
      )
    }
  }
}
