export default class BaseComponent {
  appendChild(element, selector) {
    this.element.querySelector(selector).appendChild(element)
  }

  constructor(element = null, properties = {}) {
    for (let property in properties) {
      this[property] = properties[property]
    }
    this.element = element || this.createElement()
    this.init()
  }

  init() {}
}
