import BaseComponent from './base-component'

const CLASS = 'abr-cExpanderGroup'

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`,
  EXPANDER: `.${ClassName.ROOT}-expander`,
  EXPANDER_HEADER: `.${ClassName.ROOT}-expanderHeader`
}

export default class ExpanderGroup extends BaseComponent {
  static upgradeAll() {
    let components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach((element) => {
      if (element.instance == null)
        element.instance = new ExpanderGroup(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    this.expanders = this.element.querySelectorAll(Selector.EXPANDER)
    setTimeout(() => {
      this._storeExpanderSettings()
      this._collapseAll()
      this._bindEventHandlers()
    }, 1000)
  }

  toggle(id, open) {
    if (!open) {
      this._expand(id)
    } else {
      this._collapseSingle(id)
    }
  }

  _bindEventHandlers() {
    this.expanders.forEach((expander, index) => {
      const header = expander.querySelector(Selector.EXPANDER_HEADER)
      if (header) {
        header.addEventListener(
          'click',
          (this._handleExpanderClick = () => {
            this.toggle(index, expander.dataset.open === 'true')
          })
        )
      }
    })
  }

  _storeExpanderSettings() {
    if (this.expanders) {
      this.expanders.forEach((expander, index) => {
        const header = expander.querySelector(Selector.EXPANDER_HEADER)
        expander.dataset.id = index
        expander.dataset.headerHeight = header.getBoundingClientRect().height
        expander.dataset.expandedHeight =
          expander.getBoundingClientRect().height + 1
      })
    }
  }

  _collapseAll() {
    if (this.expanders) {
      this.expanders.forEach((expander) => {
        expander.dataset.open = false
        expander.classList.remove('is-open')
        expander.style.height = `${expander.dataset.headerHeight}px`
      })
    }
  }

  _collapseSingle(id) {
    if (this.expanders) {
      this.expanders.forEach((expander) => {
        if (parseInt(expander.dataset.id) === id) {
          expander.dataset.open = false
          expander.classList.remove('is-open')
          expander.style.height = `${expander.dataset.headerHeight}px`
        }
      })
    }
  }

  _expand(id) {
    if (this.expanders) {
      this.expanders.forEach((expander) => {
        if (parseInt(expander.dataset.id) === id) {
          expander.dataset.open = true
          expander.classList.add('is-open')
          expander.style.height = `${expander.dataset.expandedHeight}px`
        }
      })
    }
  }
}
